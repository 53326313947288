<template>
    <v-row no-gutters>
        <v-col cols="12">
            <v-row justify="center" class="py-5 mt-2">
                <v-col cols="12" sm="6">
                <v-card class="pa-0">
                    <v-toolbar short flat :style="cardTitleBarStyle">
                        <v-toolbar-title :style="cardTitleBarTextStyle">Create nameserver group</v-toolbar-title>
                        <v-spacer></v-spacer>
                    </v-toolbar>
                    <v-card-text>
                    <v-form @submit.prevent="submitForm" @keyup.enter.prevent="submitForm">
                        <v-text-field v-model="newNameserverGroupLabel" ref="newNameserverGroupLabelInput" label="Name" dense></v-text-field>
                        <v-btn :style="primaryButtonStyle" @click="submitForm">Create</v-btn>
                    </v-form>
                    </v-card-text>
                </v-card>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<style scoped>

</style>

<script>
import { mapState, mapGetters } from 'vuex';

export default {
    data: () => ({
        newNameserverGroupLabel: null,
        // nameserverList: [
        //     { fqdn: 'ns1.libertydns.test', ipaddr: '1.2.3.4' },
        //     { fqdn: 'ns2.libertydns.test', ipaddr: '5.6.7.8' },
        // ],
        submitTimestamp: null,
    }),
    computed: {
        ...mapState({
            session: (state) => state.session,
            focus: (state) => state.focus,
        }),
        ...mapGetters({
            cardTitleBarStyle: 'cardTitleBarStyle',
            cardTitleBarTextStyle: 'cardTitleBarTextStyle',
            primaryButtonStyle: 'primaryButtonStyle',
        }),
    },
    watch: {
        focus() {
            this.$nextTick(() => {
                setTimeout(() => { this.activate('newNameserverGroupLabelInput'); }, 1);
            });
        },
    },
    methods: {
        activate(ref) {
            const inputRef = Array.isArray(this.$refs[ref]) ? this.$refs[ref][0] : this.$refs[ref];
            if (inputRef) {
                // more than one way to do it:
                // 1. inputRef.focus();
                // 2. const inputElement = inputRef.$el.querySelector('input'); inputElement.focus();
                // 3. const inputElement = inputRef.$el.querySelector('input'); document.getElementById(inputElement.id).focus()
                inputRef.focus();
            }
        },
        async submitForm() {
            if (Number.isInteger(this.submitTimestamp) && this.submitTimestamp + 500 > Date.now()) {
                return;
            }
            this.submitTimestamp = Date.now();
            await this.createNameserverGroup();
        },
        async createNameserverGroup() {
            try {
                this.$store.commit('loading', { createNameserverGroup: true });
                const item = {
                    label: this.newNameserverGroupLabel,
                    content: {},
                };
                const response = await this.$client.main().nameserverGroup.create(item);
                if (response?.status?.isCreated && response.status.id) {
                    this.$router.replace({ name: 'system-edit-nameserver-group', query: { id: response.status.id } });
                }
            } catch (err) {
                console.error('failed to create nameserver group', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to create nameserver group' });
            } finally {
                this.$store.commit('loading', { createNameserverGroup: false });
                this.isViewReady = true;
            }
        },
    },
    mounted() {
        this.$nextTick(() => {
            setTimeout(() => { this.activate('newNameserverGroupLabelInput'); }, 1);
        });
    },
};
</script>
